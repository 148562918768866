








































































import { buildMasterRateResponseDto, SearchBuilder } from "@/builder";
import { FormModals } from "@/components/TruckingMasterRate";
import { APagination, useBlob, useTrucking } from "@/hooks";
import { TruckingMasterDriverMapper } from "@/mapper/TruckingMasterDriver.mapper";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { TruckingMasterRateResponseDto } from "@/models/interface/trucking-master-rate";
import { ColumnDef } from "@/types";
import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  components: {
    FormModals,
  },
  methods: {
    ...mapActions({
      resetStore: "truckingMasterRateStore/resetStore",
    }),
  },
})
export default class MasterRate extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  resetStore!: () => void;

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: +this.$route.query.p || DEFAULT_PAGE,
    limit: +this.$route.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  masterRates: Pagination<TruckingMasterRateResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  masterRateTableColumns = [
    {
      title: this.$t("lbl_rate_id"),
      dataIndex: "rateId",
      key: "rateId",
      width: "150px",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_rate_name"),
      dataIndex: "rateName",
      key: "rateName",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_chart_of_account"),
      dataIndex: "rateAccountName",
      key: "rateAccountName",
      customRender: text => text || "-",
    },
    {
      key: "operation",
      align: "right",
      scopedSlots: { customRender: "operation" },
    },
  ] as ColumnDef[];

  inputSearch = (this.$route.query.q as string) || "";

  modal = {
    visible: false,
    data: buildMasterRateResponseDto(),
    toggle(): void {
      this.visible = !this.visible;
    },
    reset(): void {
      this.visible = false;
      this.data = buildMasterRateResponseDto();
    },
  };

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }

    this.pagination.limit = pageSize;

    const params = this.buildQueryParams();

    this.fetchData(params);
    this.updateQuerySearch();
  }

  fetchData(params?: RequestQueryParams): void {
    const { findListMasterRates } = useTrucking();

    this.loading.find = true;
    findListMasterRates(params)
      .then(res => {
        this.masterRates = res;
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  buildQueryParams(): RequestQueryParams {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    params.sorts = "rateName:asc";
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.inputSearch) {
      params.search = builder
        .push(["rateId", this.inputSearch], {
          like: "both",
        })
        .or()
        .push(["rateName", this.inputSearch], {
          like: "both",
        })
        .build();
    }

    return params;
  }

  resetData(): void {
    this.pagination.reset();
    this.inputSearch = "";
    this.updateQuerySearch();

    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  updateQuerySearch(): void {
    this.$router.replace({
      name: "trucking.master-rate",
      query: {
        q: this.inputSearch,
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  handleSearch(): void {
    this.pagination.reset();

    const params = this.buildQueryParams();
    this.fetchData(params);
    this.updateQuerySearch();
  }

  handleDownload(): void {
    const { downloadListMasterRates } = useTrucking();
    const { toDownload } = useBlob();

    this.loading.find = true;
    const params = TruckingMasterDriverMapper.paginationParamToParamDownload(
      this.buildQueryParams(),
      this.inputSearch
    );
    downloadListMasterRates(params)
      .then(res => {
        toDownload(res, "master_rate.xlsx");
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  handleEdit(row: TruckingMasterRateResponseDto): void {
    this.modal.data = { ...row };
    this.modal.toggle();
  }

  handleModalClose(): void {
    this.resetStore();
    this.modal.reset();
  }

  onSuccess(): void {
    this.handleModalClose();
    const params = this.buildQueryParams();
    this.fetchData(params);
  }

  mounted(): void {
    const params = this.buildQueryParams();
    this.fetchData(params);
  }
}
