var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("common.edit-text", { text: _vm.$t("lbl_master_rate") }),
        width: "50%",
        "destroy-on-close": "",
        visible: _vm.visible
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: { model: _vm.store.form, rules: _vm.formRules }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_rate_id"), prop: "rateId" } },
            [
              _c("a-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.store.form.rateId,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "rateId", $$v)
                  },
                  expression: "store.form.rateId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_rate_name"), prop: "rateName" } },
            [
              _c("a-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.store.form.rateName,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "rateName", $$v)
                  },
                  expression: "store.form.rateName"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_chart_of_account"),
                prop: "rateAccount"
              }
            },
            [
              _c("SelectAccount", {
                attrs: { "label-in-value": "" },
                model: {
                  value: _vm.store.form.rateAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "rateAccount", $$v)
                  },
                  expression: "store.form.rateAccount"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }