


































import { useTrucking } from "@/hooks";
import { TruckingMasterDriverMapper } from "@/mapper/TruckingMasterDriver.mapper";
import MNotification from "@/mixins/MNotification.vue";
import {
  TruckingMasterRateResponseDto,
  TruckingMasterRateUpdateRequestDto,
} from "@/models/interface/trucking-master-rate";
import { FormValue, State } from "@/store/truckingMasterRate.store";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { PropType } from "vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import SelectAccount from "../custom/select/SelectAccount.vue";

@Component({
  components: {
    SelectAccount,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.truckingMasterRateStore as State,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "truckingMasterRateStore/setForm",
    }),
    ...mapActions({
      resetStore: "truckingMasterRateStore/resetStore",
    }),
  },
})
export default class FormModals extends Mixins(MNotification) {
  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;
  resetStore!: () => void;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({
    type: Object as PropType<TruckingMasterRateResponseDto>,
    required: true,
  })
  data!: TruckingMasterRateResponseDto;

  loading = false;

  formRules = {
    rateId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    rateName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    rateAccount: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  handleCancel(): void {
    this.$emit("close");
  }

  validateForm(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      this.handleUpdate(this.store.form.id, this.store.form);
    });
  }

  handleUpdate(id: string, form: FormValue): void {
    const { updateMasterRate } = useTrucking();
    const req: TruckingMasterRateUpdateRequestDto =
      TruckingMasterDriverMapper.toMasterRateUpdateDTO(form);
    this.loading = true;

    updateMasterRate(id, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success");
        this.resetStore();
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  emitSuccess(): void {
    this.$emit("success");
  }

  @Watch("data")
  onDataChange(newValue: TruckingMasterRateResponseDto): void {
    let rateAccount: LabelInValue | undefined = undefined;
    if (newValue.rateAccountId && newValue.rateAccountName) {
      rateAccount = {
        key: newValue.rateAccountId,
        label: newValue.rateAccountName,
      };
    }
    this.setForm({
      ...newValue,
      rateAccount,
    });
  }
}
